import {
  ArticleTileAttributes,
  BookmarkVideoTileAttributes,
  ThumbnailTileType,
  TileType,
  UploadTileAttributes,
  UploadFileTileTypes,
  UploadType,
  UploadedVideoTileAttributes,
  TileTypes,
  CollectionTileType,
} from 'types/tile';
import { IconName } from 'components/Icons';
import dayjs from 'dayjs';
import { UseSuspenseInfiniteQueryOptions } from '@tanstack/react-query';
import { getFormattedTileChildrenByID } from 'api/wrappers/TileAPI';
import { emptyCoreUser, getFileType, getPageOffset, shortenString } from './Utils';
import { maxTileTitleLength, myTilesID, systemUserID } from './constants';

export const tileTypeLabels = {
  BOOKMARK: 'Link',
  VIDEO: 'Video',
  ARTICLE: 'Article',
  COLLECTION: 'Collection',
  CAROUSEL: 'Carousel',
  KUDOS: 'Kudos',
  UPLOAD: 'Upload',
  XERO: 'Xero',
  IMAGE_BANK: 'Image Bank',
  LEARN: 'Learn',
  BOXSET: 'Boxset',
  SCORM: 'SCORM',
  UPLOADED_VIDEO: 'Uploaded Video',
  SEASON: 'Season',
  '': 'Redacted',
};

const getFileIcon = (type: string): IconName => {
  switch (type) {
    case 'png':
      return 'png';
    case 'jpg':
    case 'jpeg':
      return 'jpg';
    case 'xls':
    case 'xlsx':
      return 'xls';
    case 'doc':
    case 'docx':
      return 'doc';
    case 'ppt':
    case 'pptx':
      return 'ppt';
    case 'pdf':
      return 'pdf';
    case 'gif':
      return 'gif';
    case 'csv':
      return 'csv';
    default:
      return 'file';
  }
};

export const getTileIcon = (type: string, tile?: TileType): IconName => {
  switch (type) {
    case 'UPLOAD':
      if (!tile) return 'file';
      const fileName = (tile?.attributes as UploadTileAttributes).upload.fileName;

      return getFileIcon(getFileType(fileName || ''));
    case 'KUDOS':
      return 'lightning';
    case 'VIDEO':
      return 'play-round';
    case 'ARTICLE':
    case 'INFO':
      return 'article';
    case 'XERO':
      return 'umbrella';
    case 'BOOKMARK':
      return 'tile';
    case 'COLLECTION':
      return 'collection';
    case 'BOXSET':
      return 'boxsets';
    case 'SCORM':
      return 'scorm';
    case 'UPLOADED_VIDEO':
      return 'play-round';
    case 'LEARN':
      return 'learn';
    default:
      return '';
  }
};

export const learnItemTileTypes = ['BOOKMARK', 'VIDEO', 'ARTICLE', 'UPLOADED_VIDEO', 'UPLOAD', 'SCORM'];
export const learnOnlyTileTypes = ['SCORM', 'BOXSET', 'SEASON'];

export const emptyThumbnailTile = (settings?: {
  tileId?: string;
  url?: string;
  tileType?: 'VIDEO' | 'BOOKMARK' | 'ARTICLE' | 'UPLOAD' | 'BOXSET' | 'SEASON' | 'SCORM' | 'UPLOADED_VIDEO' | 'COLLECTION';
  isManagedByOrganisation?: boolean;
  name?: string;
  upload?: UploadType;
  image?: { key: string; readPresignURL: string } | null;
  primarySource?: 'hub' | 'learn';
}): ThumbnailTileType => {
  const type = settings?.tileType || 'BOOKMARK';
  const emptyThumbnail = {
    colour: '',
    url: settings?.image ? settings?.image.readPresignURL : '',
    urlKey: settings?.image ? settings.image.key : '',
    croppedURL: '',
    croppedURLKey: '',
    filter: 'none',
    transforms: {
      rotation: 0,
      isFlippedVertical: false,
      isFlippedHorizontal: false,
      zoom: 1,
    },
  };
  const emptyTextOptions = {
    isVisible: true,
    isShadowVisible: true,
    isDarkMode: false,
  };
  const tile = {
    id: settings?.tileId || '',
    organisationID: '',
    name: settings?.name ? shortenString(settings.name, maxTileTitleLength) : '',
    description: '',
    cloneSourceID: systemUserID,
    createdBy: '',
    createdByDetails: emptyCoreUser,
    createdAt: '',
    updatedAt: '',
    slug: '',
    type,
    isEnabled: true,
    legacyID: '',
    deletedAt: '',
    isManagedByOrganisation: settings?.isManagedByOrganisation || false,
    isAllowedExternally: settings?.tileType === 'UPLOAD' ? false : true,
    isHiddenFromOrganisation: false,
    attributes: {
      thumbnail: emptyThumbnail,
      textOptions: emptyTextOptions,
      heroImage: emptyThumbnail,
      heroTextOptions: emptyTextOptions,
    },
    dueAt: null,
    startAt: null,
    endAt: null,
    isMandatory: false,
    primarySource: settings?.primarySource || 'hub',
  };

  if (tile.type === 'BOOKMARK' || tile.type === 'VIDEO') (tile.attributes as BookmarkVideoTileAttributes).url = settings?.url || '';

  if (tile.type === 'UPLOAD' || tile.type === 'SCORM' || tile.type === 'UPLOADED_VIDEO')
    (tile.attributes as UploadTileAttributes).upload = settings?.upload || {
      fileName: '',
      scanID: '',
      fileSizeInBytes: 0,
      fileType: '',
      scannedAt: new Date().toISOString(),
      downloadURL: '',
    };

  if (tile.type === 'UPLOADED_VIDEO') {
    tile.attributes = {
      ...(tile.attributes as UploadedVideoTileAttributes),
      transcode: { jobID: '' },
      totalFileSizeInBytes: 0,
      transcodedAt: '',
      uploadedBy: '',
      videoLengthInSeconds: 0,
      videoResolutions: [],
      path: '',
    } as UploadedVideoTileAttributes;
  }

  if (tile.type === 'ARTICLE')
    (tile.attributes as ArticleTileAttributes).article = {
      title: '',
      description: '',
      content: '',
      isAuthorVisible: true,
      isCritical: false,
      isReadReceiptRequired: false,
      estimatedReadTime: 5,
      thumbnail: emptyThumbnail,
      textOptions: emptyTextOptions,
    };

  if (learnItemTileTypes.includes(tile.type)) {
    (tile.attributes as BookmarkVideoTileAttributes | ArticleTileAttributes | UploadTileAttributes).requiredTimeInSeconds = 0;
  }

  return tile as unknown as ThumbnailTileType;
};

export const emptyCollectionTile = (tileId?: string, isManagedByOrganisation?: boolean, name?: string): CollectionTileType => {
  return {
    id: tileId || '',
    organisationID: '',
    name: name || '',
    description: '',
    createdBy: '',
    createdAt: '',
    updatedAt: '',
    cloneSourceID: systemUserID,
    slug: '',
    type: 'COLLECTION',
    isEnabled: true,
    legacyID: '',
    deletedAt: '',
    isManagedByOrganisation: isManagedByOrganisation || false,
    isAllowedExternally: true,
    isHiddenFromOrganisation: false,
    createdByDetails: emptyCoreUser,
    dueAt: null,
    startAt: null,
    endAt: null,
    isMandatory: false,
    primarySource: 'hub',
    children: [],
    attributes: {
      thumbnail: {
        colour: '',
        url: '',
        urlKey: '',
        croppedURL: '',
        croppedURLKey: '',
        filter: 'none',
        transforms: {
          rotation: 0,
          isFlippedVertical: false,
          isFlippedHorizontal: false,
          zoom: 1,
        },
      },
      heroImage: {
        colour: '',
        url: '',
        urlKey: '',
        croppedURL: '',
        croppedURLKey: '',
        filter: 'none',
        transforms: {
          rotation: 0,
          isFlippedVertical: false,
          isFlippedHorizontal: false,
          zoom: 1,
        },
      },
      hasExternalAccessToken: false,
      childrenCount: 0,
      externalShareURL: '',
      externalAccessToken: '',
      textOptions: {
        isVisible: true,
        isShadowVisible: true,
        isDarkMode: false,
      },
    },
    reactionSummary: {
      totalCount: 0,
    },
  };
};

export interface LearnTagType {
  text: string;
  color: string;
  icon?: IconName;
}

export const itemIsNew = (tile: TileType) => {
  return !!(dayjs(tile.createdAt).isAfter(dayjs().subtract(3, 'day')) && !tile.userData?.isCompleted && tile.userData?.progress === 0);
};

export const isTileUnstarted = (tile: TileType) => {
  return !!(!tile.userData?.isCompleted && tile.userData?.progress === 0);
};

export const getAllowedFileTypes = (tileType?: UploadFileTileTypes) => {
  switch (tileType) {
    case 'SCORM':
      return ['zip'];
    case 'UPLOADED_VIDEO':
      return ['3G2', '3GP', 'AVI', 'F4V', 'FLV', 'HLS', 'IMF', 'MOV', 'MP4', 'MPEG-1', 'MPEG-2', 'MPEG-4', 'MPEG-TS', 'MXF', 'WebM'];
    default:
      return ['png', 'jpg', 'jpeg', 'gif', 'pdf', 'ppt', 'doc', 'docx', 'xls', 'pptx', 'xlsx'];
  }
};

export const myTilesInfiniteGridOptions: (userUUID: string, activatedTileTypes: TileTypes[]) => UseSuspenseInfiniteQueryOptions = (
  userUUID: string,
  activatedTileTypes: TileTypes[],
) => ({
  queryKey: [myTilesID, 'infiniteTileGrid'],
  queryFn: async (context) => {
    const res = await getFormattedTileChildrenByID(myTilesID, {
      limit: 25,
      offset: getPageOffset(25, context.pageParam as number),
      viewAs: userUUID,
      showRedacted: false,
      type: activatedTileTypes.join(','),
    });
    return res;
  },
  initialPageParam: 1,
  getNextPageParam: (lastPage, allPages, lastPageParam) => (lastPageParam as number) + 1,
  getPreviousPageParam: (firstPage, allPages, firstPageParam) => (firstPageParam as number) - 1,
});
