/* eslint-disable no-bitwise */
/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * A helper function to parse the organisationId from the URL.
 */

import dayjs, { Dayjs } from 'dayjs';
import dayjsUTC from 'dayjs/plugin/utc';
import React, { ComponentType, LazyExoticComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Sentry from '@sentry/react';
import { TableFilter } from 'components/Table/TableHeaderActions/TableFilters/TableFilters';
import { UserCoreType } from 'types/users';
import { ArticleTileType, CollectionUser, TileType } from 'types/tile';
import { BasicAdminColumnFieldType } from 'components/Table/tableColumnFields';
import { getOrganisationID } from 'APIHandler';
import { AdminArticlesCreateFormValues } from 'pages/Admin/AdminArticles/AdminArticlesCreate';
import { platform } from '@todesktop/client-core';
import { OrganisationAttributeType } from 'types/organisations';
import { systemUserID } from './constants';

dayjs.extend(dayjsUTC);

// For removing the presigns
export const getPathFromUrl = (url: string) => {
  return url.split('?')[0];
};

export const getPageOffset = (perPage: number, page: number) => {
  return page === 1 ? 0 : perPage * (page - 1);
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const arrayToSentence = (arr: string[], connector: string) => {
  if (arr.length === 0) return '';
  if (arr.length === 1) return arr[0];
  const last = arr.pop();
  return `${arr.join(', ')} ${connector} ${last}`;
};

export const parseOrgGUID = (url: string) => {
  const params = url.split('/');
  return params.length > 1 ? params[1] : '';
};

export const isUploadedImage = (url: string) => {
  return url.includes('hula-thumbnail-upload') || url.includes('huler-thumbnail-upload');
};
export const isPresignedImage = (url: string) => {
  return url?.includes('Amz-Credential') && url?.includes('Amz-Algorithm') && url?.includes('Amz-Security-Token');
};

export const parsePresignedDataFromURL = (url: string) => {
  if (isUploadedImage(url)) {
    const question = url.indexOf('?');
    return question > 0 ? url.substring(0, question) : url;
  }

  return url;
};

export const extractFileName = (url: string) => {
  return url ? url.substring(url.lastIndexOf('/') + 1) : undefined;
};

export const b64DecodeUnicode = (str: string) => {
  if (!str) {
    return '';
  }

  let stringToDecode = str;

  // If this is a data uri, it will be prefixed with something like data:text/html;base64,
  if (str.startsWith('data:')) {
    stringToDecode = str.substring(str.indexOf(',') + 1);
  }

  // Ensure valid base64 string by checking its format
  const base64Pattern = /^[A-Za-z0-9+/=]+$/;
  if (!base64Pattern.test(stringToDecode)) {
    return str; // Return the original string if it's not valid base64
  }

  // Ensure valid base64 string by removing invalid characters and fixing padding
  stringToDecode = stringToDecode.replace(/[^A-Za-z0-9+/=]/g, '');

  // length of the base64 string must be a multiple of 4
  // add padding characters (=) until the string becomes multiple of 4
  while (stringToDecode.length % 4 !== 0) {
    stringToDecode += '=';
  }

  try {
    const decoded = atob(stringToDecode)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('');
    return decodeURIComponent(decoded);
  } catch (error) {
    Sentry.captureException(error);
    return str;
  }
};

export const b64EncodeUnicode = (str: string) => {
  // first we use encodeURIComponent to get percent-encoded UTF-8,
  // then we convert the percent encodings into raw bytes which
  // can be fed into btoa.
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1: number) {
      const str2: unknown = `0x${p1}`;
      return String.fromCharCode(str2 as number);
    }),
  );
};

export const convertToHoursAndMinutes = (mins: number) => {
  if (mins === 0) return '0m';

  const hours = mins / 60;
  const rhours = Math.floor(hours);
  const newMinutes = (hours - rhours) * 60;
  const rminutes = Math.round(newMinutes);

  return rhours > 0 ? `${rhours}h ${rminutes}m` : newMinutes >= 1 ? `${rminutes}m` : '< 1m';
};

export const formatDate = (date: number | string | Date) => {
  return dayjs(date).format('YYYY-MM-DD');
};

export const formatDateTime = (date: number | string | Date) => {
  return dayjs(date).format('YYYY-MM-DD HH:mm');
};

export const formatDateNumbersOnly = (date: number | string | Date) => {
  return dayjs(date).format('DDMMYYYY');
};

// When using FormattedDate (react-intl), it thinks the DD is the month, so if the day is more than 12, it errors and says invalid date
// For this reason, we need to ensure it sends it back in a different format for translation and set 2nd param to true
export const unixToDate = (unix: number | string, translation?: boolean) => {
  return dayjs.unix(Number(unix)).format(translation ? 'MM/DD/YYYY' : 'DD/MM/YYYY');
};

export const base64StringtoFile = (base64String: string, filename: string) => {
  const arr = base64String.split(',');
  const arrMatch = arr[0].match(/:(.*?);/);
  const mime = Array.isArray(arrMatch) && arrMatch.length > 1 ? arrMatch[1] : '';
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // to be fixed
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

// Toggles an item from an array
export const toggleValue = (array: string[], value: string) => {
  const newArray = [...array];
  const index = newArray.indexOf(value);

  if (index === -1) {
    newArray.push(value);
  } else {
    newArray.splice(index, 1);
  }

  return newArray;
};

export const randomIntFromInterval = (min: number, max: number) => {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
};

/**
 * Convert a hex value to RGB.
 * Also works for shorthand hex codes.
 */
export const hexToRgb = (hex: string) => {
  if (hex === undefined || hex === '' || hex === null) return '';
  return hex
    ?.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_m, r, g, b) => `#${r}${r}${g}${g}${b}${b}`)
    ?.substring(1)
    ?.match(/.{2}/g)
    ?.map((x) => parseInt(x, 16))
    ?.join();
};

interface LazyComp extends LazyExoticComponent<ComponentType<any>> {
  preload?: () => Promise<{ default: ComponentType<any> }>;
}

export const lazyPreload = (comp: () => Promise<{ default: ComponentType<any> }>) => {
  const LazyComponent: LazyComp = React.lazy(/* webpackPrefetch: true */ comp);
  LazyComponent.preload = comp;
  return LazyComponent;
};

export const emailValidate = (email: string) => {
  return (email.includes('@') && email.includes('.') && email.length > 3 && email.length < 254) ?? false;
};

export const getGreetingTime = (date: Dayjs, name: string) => {
  if (!date || !date.isValid()) {
    return '';
  }
  const splitAfternoon = 12;
  const splitEvening = 18;
  const currentHour = parseFloat(date.format('HH'));

  if (currentHour >= splitAfternoon && currentHour < splitEvening) {
    return <FormattedMessage id='greeting.afternoon' defaultMessage='👋 Good Afternoon, {name}' description='Afternoon greeting' values={{ name }} />;
  }
  if (currentHour >= splitEvening) {
    return <FormattedMessage id='greeting.evening' defaultMessage='😴 Good Evening, {name}' description='Evening greeting' values={{ name }} />;
  }
  return <FormattedMessage id='greeting.morning' defaultMessage='☀️ Good Morning, {name}' description='Morning greeting' values={{ name }} />;
};

export const isUrl = (string: string) => {
  const regexp =
    /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  return regexp.test(string);
};

export const shortenString = (str: string, maxLength: number) => {
  return str?.length > maxLength ? `${str.substring(0, maxLength - 3)}...` : str;
};

export const getFileType = (fileName: string) => {
  const type = fileName.split('.').pop();

  if (type === fileName) {
    return '';
  }

  return `${type}`;
};

export const convertToSnakeCase = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1_$2')
    .replace(/\s+/g, '_')
    .toLowerCase();
};

export const prependHTTP = (url: string): string => {
  if (!url.startsWith('http') && !url.startsWith('ftp')) {
    return `http://${url}`;
  }

  return url;
};

export const isEmptyObject = (obj: any) => typeof obj === 'object' && Object.keys(obj).length === 0;

export const formatAdminFiltersForAPI = (filters: TableFilter[]) =>
  filters.map((filter) => {
    return {
      operationType: filter.operationType,
      name: filter.id,
      value: filter.value,
      fields: filter.fields,
    };
  });

export const entitiesToText = (str: string): string => {
  const textArea = document.createElement('textarea');
  textArea.innerHTML = str;
  return textArea.value;
};

export const removeHTMLTags = (str: string): string => {
  const noTags = str.replace(/(<([^>]+)>)/gi, ''); // Remove tags
  const noEntities = entitiesToText(noTags); // Convert entities to plain text
  const message = noEntities.replace(/(\r\n|\n|\r)/gm, ''); // Remove new lines (\n)

  return message;
};

export const getH1TextFromHTML = (content: string) => content.match(/<h1.*?>(.*?)<\/h1>/g)?.map((val) => removeHTMLTags(val));

export const getReadableFileSize = (fileSizeInBytes: number) => {
  // Show fileSizeInBytes in KB, MB etc
  const i = fileSizeInBytes === 0 ? 0 : Math.floor(Math.log(fileSizeInBytes) / Math.log(1024));
  return `${+(fileSizeInBytes / 1024 ** i).toFixed(2) * 1} ${['B', 'KB', 'MB', 'GB', 'TB'][i]}`;
};

export const isInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const adjustDateToUTC = (date: string | number | Date): Date => {
  const jsDate = new Date(date);
  const adjustedDate = new Date(date).setHours(jsDate.getHours() + jsDate.getTimezoneOffset() / 60);
  return new Date(adjustedDate);
};

export const convertDateToUTCString = (date: Date): string => {
  const tzoffset = date.getTimezoneOffset() * 60000; //offset in milliseconds
  return new Date(date.valueOf() - tzoffset).toISOString();
};

export const convertStringToDate = (date: string | null, useTimezone?: boolean) => {
  if (date) {
    return useTimezone ? new Date(date) : adjustDateToUTC(date);
  } else {
    return null;
  }
};
export const convertDateToISOString = (date: Date | null, useTimezone?: boolean) => {
  if (date) {
    return useTimezone ? date.toISOString() : convertDateToUTCString(date);
  } else {
    return null;
  }
};

export const UTCToLocalDate = (date: string | number | Date): Date => {
  return dayjs.utc(date).local().toDate();
};

export const returnUrlFriendlyFormat = (input: string, replacementForSpaces = '-') => {
  // Change the Organisation ID into a URL friendly format
  const formattedInput = input
    .toLowerCase()
    .replace(/ /g, replacementForSpaces) // Replace spaces with given character
    .replace(/[^\w-]+/g, ''); // Remove special characters
  return formattedInput;
};

export const filtersToObject = (filters: TableFilter[]) => {
  const objFilters: { [val: string]: string } = {};

  filters.map(
    (filter) =>
      (objFilters[filter.id] =
        filter.inputType === 'date' && filter.value !== '' ? dayjs(filter.value).format('YYYY-MM-DD') : filter.value.toString()),
  );
  return objFilters;
};

export const cleanSearchParams = (params: URLSearchParams) => {
  const keysForDel: string[] = [];
  params.forEach((value, key) => {
    if (
      value === 'undefined' ||
      value === '' ||
      value === undefined ||
      value === null ||
      (typeof value === 'string' && value.toLowerCase() === 'all')
    ) {
      keysForDel.push(key);
    }
  });

  keysForDel.forEach((key) => {
    params.delete(key);
  });

  return params;
};

export const removeEmptyValuesFromObject = (obj: { [key: string]: unknown }) => {
  const data = JSON.parse(JSON.stringify(obj));
  Object.entries(data).forEach((entry) => {
    if (entry[1] === '' || entry[1] === null || entry[1] === undefined) {
      delete data[entry[0]];
    }
  });

  return data;
};

export const canClearColumnFilters = (currentColumns: BasicAdminColumnFieldType[], defaultColumns: BasicAdminColumnFieldType[]) => {
  const columnsHaveChanged = JSON.stringify(currentColumns) !== JSON.stringify(defaultColumns);
  return columnsHaveChanged;
};

export const canClearAllAdminFilters = (
  currentColumns: BasicAdminColumnFieldType[],
  defaultColumns: BasicAdminColumnFieldType[],
  filters: TableFilter[],
) => {
  const columnsHaveChanged = canClearColumnFilters(currentColumns, defaultColumns);
  const filtersAreApplied = filters.map((filter) => filter.value).some((v) => v);
  return columnsHaveChanged || filtersAreApplied;
};

export const numberWithLeadingZero = (value: number) => {
  if (!value) return '0';
  return value < 10 ? `0${value}` : `${value}`;
};

// The following functions/variables do not require testing as they are static values or built in window/document values

export const getWindowWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const CookieAcceptanceData = {
  smallText:
    'By clicking “Accept All Cookies”, you agree to cookies being stored on your device to improve site navigation, provide analytics, and improve our marketing.',
  largeText:
    'Visiting websites may store or retrieve information on your browser, mostly in the form of cookies. This data can be about you, your preferences or your device - most of the time it is used in the functionality of the website you are on. Normally, we cannot directly identify you from this information. At Huler, we respect your right to privacy, you can choose not to allow some types of cookies. Click on the headings below to see more information on each type of cookie, but please remember blocking cookies may impact your experience.',
  image: 'https://i.giphy.com/media/HGe4zsOVo7Jvy/giphy.webp',
  privacyPolicyURL: 'https://huler.io/privacy-policy',
  appName: 'HulerApp',
};

export const fullName = (user: UserCoreType | CollectionUser | undefined) => {
  return user ? `${user.firstName} ${user.lastName}` : '';
};

export const emptyCoreUser: UserCoreType = {
  id: '',
  organisationID: '',
  firstName: '',
  lastName: '',
  email: '',
  attributes: {
    department: '',
    gender: '',
    geo: '',
    location: '',
    startedat: '',
    role: '',
  },
  lastSeen: '',
};

export const systemUser = { ...emptyCoreUser, userUUID: systemUserID, firstName: 'System', lastName: 'User', email: 'system@huler.io' };

export const getPercentageIncrease = (from: number, to: number) => {
  let percent;

  if (to !== 0) {
    if (from !== 0) {
      percent = ((to - from) / from) * 100;
    } else {
      percent = to * 100;
    }
  } else {
    percent = -from * 100;
  }

  return Math.floor(percent);
};

export const calculatePercentage = (val: number, max: number): number => {
  if (max === 0) return 0;
  const a = (val / max) * 100;
  return a;
};

export const getPreviousMonday = (date?: Date) => {
  const startDate = date ? dayjs(date) : dayjs();

  return startDate.subtract(7 + ((startDate.day() + 6) % 7), 'days').toDate();
};

export const isImageFileType = (fileType: string) => {
  switch (fileType) {
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'gif':
      return true;
    default:
      return false;
  }
};

export const checkIfImageExists = (url: string, callback: (exists: boolean) => void) => {
  const image = new Image();
  image.src = url;

  if (image.complete) {
    callback(true);
  } else {
    image.onload = () => {
      callback(true);
    };

    image.onerror = () => {
      callback(false);
    };
  }
};

export const generateRequestURL = (url: string, params: any) => {
  const queryString = new URLSearchParams(params);

  return `${url}?${cleanSearchParams(queryString)}`;
};

export const formatInclusiveEndDate = (date: dayjs.ConfigType, formatToISOString = false) =>
  dayjs(date)
    .add(1, 'days')
    .format(formatToISOString ? 'YYYY-MM-DDTHH:mm:ss[Z]' : 'YYYY-MM-DD');

export const formatInclusiveEndTime = (date: dayjs.ConfigType) => dayjs(date).hour(23).minute(59).second(59).format('YYYY-MM-DDTHH:mm:ss[Z]');

interface FormatLargeNumberOptionProps {
  startingNum?: number;
  decimalPlaces?: number;
}

export const formatLargeNumber = (numToFormat: number, options?: FormatLargeNumberOptionProps): string => {
  const int = Math.floor(numToFormat);
  const unit = int > 999999 ? 'm' : 'k';
  const numToDivide = int > 999999 ? 1000000 : 1000;

  // If the given value is positive and less than the startingNum, or the value is negative and more than the startingNum, don't format it
  if ((int >= 0 && int < (options?.startingNum || 1000)) || (int < 0 && int > (options?.startingNum || -1000))) {
    return int.toString();
  } else {
    return `${parseFloat((int / numToDivide).toFixed(options?.decimalPlaces || 2)).toLocaleString()}${unit}`;
  }
};

export const cloneIDisEditable = (id: string) => id === systemUserID || id === '';

// Presign wildcard urls are stored in an object for each org that is logged into within a session
const getWildcardURLDataFromCache = () => {
  const data = sessionStorage.getItem('readPresignWildcardURL');

  if (!data || !isJsonString(data)) return {};

  return JSON.parse(data);
};

export const setWildcardURLInCache = (url: string) => {
  const presignObj = getWildcardURLDataFromCache();
  const orgPath = getOrganisationID();

  sessionStorage.setItem('readPresignWildcardURL', JSON.stringify({ ...presignObj, [orgPath]: url }));
};

const getCurrentOrgPresignFromCache = () => {
  const presignObj = getWildcardURLDataFromCache();
  const orgPath = getOrganisationID();

  return orgPath in presignObj ? presignObj[orgPath] : '';
};

export const formatWildcardURL = (imgKey: string, presignWildcardURL?: string) => {
  if (!imgKey) return '';
  const presignURL = presignWildcardURL ? presignWildcardURL : getCurrentOrgPresignFromCache();
  // This function assumes the presign has already been generated with getOrgPresign or similar
  return presignURL.replace('*', imgKey);
};

export const prototypingTileObject: TileType = {
  id: 'b4afe95b-4fb0-49a9-a507-3f6db22f02f9',
  organisationID: '1d6483b2-caf6-4acb-8de8-4d48b976af31',
  cloneSourceID: '00000000-0000-0000-0000-000000000000',
  name: 'Official Ferrari websiteFerrari logoFerrari lo...',
  description:
    'Ferrari - All the official contents of the Maranello based carmaker: all the cars in the range and the great historic cars, the official Ferrari dealers, the online store and the sports activities of a brand that has distinguished Italian excellence around the world since 1947...',
  createdBy: '85364cdc-36df-4b81-9298-dce449c93c72',
  createdAt: '2023-11-10T14:56:53Z',
  updatedAt: '2023-11-10T14:56:53Z',
  slug: '',
  type: 'BOOKMARK',
  isEnabled: true,
  isManagedByOrganisation: false,
  isAllowedExternally: true,
  legacyID: '',
  deletedAt: '',
  isHiddenFromOrganisation: false,
  dueAt: null,
  startAt: null,
  endAt: null,
  isMandatory: false,
  reactionSummary: {
    totalCount: 0,
  },
  primarySource: 'hub',
  children: [],
  attributes: {
    url: 'http://ferrari.com',
    thumbnail: {
      colour: '',
      url: 'https://images.unsplash.com/photo-1497215728101-856f4ea42174?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b2ZmaWNlfGVufDB8fDB8fHww',
      croppedURL: '',
      filter: 'none',
      transforms: {
        rotation: 0,
        isFlippedVertical: false,
        isFlippedHorizontal: false,
        zoom: 1,
      },
      urlKey: 'd5a11cf8-ae65-405c-b646-cbbb501898ef.jpeg',
      croppedURLKey: '',
    },
    textOptions: {
      isVisible: true,
      isShadowVisible: true,
      isDarkMode: false,
    },
    heroImage: {
      colour: '',
      url: 'https://images.unsplash.com/photo-1497215728101-856f4ea42174?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8b2ZmaWNlfGVufDB8fDB8fHww',
      croppedURL: '',
      filter: 'none',
      transforms: {
        rotation: 0,
        isFlippedVertical: false,
        isFlippedHorizontal: false,
        zoom: 1,
      },
      urlKey: 'd5a11cf8-ae65-405c-b646-cbbb501898ef.jpeg',
      croppedURLKey: '',
    },
    heroTextOptions: {
      isVisible: true,
      isShadowVisible: true,
      isDarkMode: false,
    },
    requiredTimeInSeconds: 0,
  },
  createdByDetails: {
    id: '85364cdc-36df-4b81-9298-dce449c93c72',
    organisationID: '1d6483b2-caf6-4acb-8de8-4d48b976af31',
    firstName: 'Joe',
    lastName: 'C',
    email: 'joe.ciavucco@huler.io',
    attributes: {
      department: 'd9909113-43bc-49f0-9498-099032174aa3',
      gender: '461db8ec-ed1d-4d56-a260-e65e6a3a8de6',
      geo: 'b309a5d4-de89-4081-9939-be4133842448',
      location: '9fbd21bf-4975-403e-9ae7-1fb09eaf6918',
      startedat: '2022-04-13T19:00:00Z',
      role: '7e2456c6-8bfa-4ef4-90d3-1e0b256cac8b',
    },
    lastSeen: '2024-01-06T10:26:41Z',
  },
};

// prettify array of file upload types
export const prettifyFileUploadTypes = (fileTypes: string[]) => {
  const prettyTypes = fileTypes.map((type) => type.toUpperCase());
  return prettyTypes.join(', ');
};

export const hasIndexedDBSupport = () => !!('indexedDB' in window);

export const isiOSDevice = () => {
  return navigator.userAgent.match(/ipad|iphone/i) || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints);
};

export const audiencesPendingChangesMessage = 'Changes to the user list will be made active only upon save of the audience.';

export const onlyFetchOnceParamsReactQuery = {
  refetchInterval: Infinity,
  refetchIntervalInBackground: false,
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const defaultArticle = (id?: string, name?: string) => {
  return {
    id: id || '',
    organisationID: '',
    cloneSourceID: systemUserID,
    name: name || '',
    description: '',
    createdBy: '',
    createdByDetails: emptyCoreUser,
    createdAt: '',
    updatedAt: '',
    slug: '',
    type: 'ARTICLE',
    isEnabled: true,
    legacyID: '',
    deletedAt: '',
    isAllowedExternally: false,
    isManagedByOrganisation: true,
    isHiddenFromOrganisation: false,
    isMandatory: false,
    attributes: {
      thumbnail: {
        colour: '',
        url: '',
        urlKey: '',
        croppedURL: '',
        croppedURLKey: '',
        filter: 'none',
        transforms: {
          rotation: 0,
          isFlippedVertical: false,
          isFlippedHorizontal: false,
          zoom: 1,
        },
      },
      textOptions: {
        isVisible: true,
        isShadowVisible: true,
        isDarkMode: false,
      },
      article: {
        title: '',
        description: '',
        content: '',
        isAuthorVisible: true,
        isCritical: false,
        isReadReceiptRequired: false,
        estimatedReadTime: 0,
        thumbnail: {
          colour: '',
          url: '',
          urlKey: '',
          croppedURL: '',
          croppedURLKey: '',
          filter: 'none',
          transforms: {
            rotation: 0,
            isFlippedVertical: false,
            isFlippedHorizontal: false,
            zoom: 1,
          },
        },
        textOptions: {
          isVisible: true,
          isShadowVisible: true,
          isDarkMode: false,
        },
      },
    },
  } as ArticleTileType;
};

export const formatArticleValues = (values: AdminArticlesCreateFormValues, uneditedTile: ArticleTileType) => {
  const existingImage = uneditedTile.attributes.thumbnail.urlKey || uneditedTile.attributes.thumbnail.url;
  const existingHeader = uneditedTile.attributes.article.thumbnail.urlKey || uneditedTile.attributes.article.thumbnail.url;

  const headerAndTileEqual = existingImage === existingHeader;
  return {
    id: values.id,
    organisationID: '',
    cloneSourceID: systemUserID,
    name: values.title,
    description: values.intro,
    createdBy: values.createdBy,
    createdByDetails: emptyCoreUser,
    createdAt: '',
    updatedAt: '',
    slug: '',
    type: 'ARTICLE',
    isEnabled: true,
    legacyID: '',
    deletedAt: '',
    isManagedByOrganisation: true,
    isHiddenFromOrganisation: false,
    isAllowedExternally: values.isAllowedExternally || false,
    audiences: values.audiences.map((audience) => {
      return { id: audience };
    }),
    isMandatory: values.isMandatory,
    attributes: {
      thumbnail: {
        colour: '',
        url: !headerAndTileEqual && existingImage ? existingImage : values.thumbnailURLKey,
        urlKey: '',
        croppedURL: '',
        croppedURLKey: '',
        filter: 'none',
        transforms: {
          rotation: 0,
          isFlippedVertical: false,
          isFlippedHorizontal: false,
          zoom: 1,
        },
      },
      textOptions: {
        isVisible: true,
        isShadowVisible: true,
        isDarkMode: false,
      },
      heroImage: {
        colour: '',
        url: values.thumbnailURLKey,
        urlKey: '',
        croppedURL: '',
        croppedURLKey: '',
        filter: 'none',
        transforms: {
          rotation: 0,
          isFlippedVertical: false,
          isFlippedHorizontal: false,
          zoom: 1,
        },
      },
      article: {
        title: values.title,
        description: values.intro,
        content: values.content,
        isAuthorVisible: values.showAuthor,
        isCritical: values.isCritical,
        isReadReceiptRequired: values.readAcceptance,
        estimatedReadTime: values.readTime * 60,
        thumbnail: {
          colour: '',
          url: values.thumbnailURLKey,
          urlKey: '',
          croppedURL: '',
          croppedURLKey: '',
          filter: 'none',
          transforms: {
            rotation: 0,
            isFlippedVertical: false,
            isFlippedHorizontal: false,
            zoom: 1,
          },
        },
        textOptions: {
          isVisible: true,
          isShadowVisible: true,
          isDarkMode: false,
        },
      },
    },
  } as ArticleTileType;
};

export const errorStatusToErrorPageProp = (status: number) => {
  switch (status) {
    case 403:
      return 'forbidden';
    case 404:
      return 'not-found';
    case 503:
      return 'no-internet';
    default:
      return 'generic';
  }
};

export const openLinkInNewWindow = (url: string) => {
  return platform.todesktop.isDesktopApp() ? platform.os.openURL(url) : window.open(url, '_blank');
};

export const attributeValueToLabel = (attribute: OrganisationAttributeType, value: string) =>
  attribute.possibleValues?.find((val) => val.id === value)?.value;

export const getDateMidnight = (date: string | number | Date | null) => {
  if (date) {
    const d = new Date(date);

    const utcMidnight = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    return adjustDateToUTC(utcMidnight);
  } else {
    return null;
  }
};

export const wysiwygHasContent = (value: string | undefined) => !!value && value.replace(/<[^>]+>/g, '') !== '';

// Used for filtering objects (pick for props you want, omit for everything except)
export const pickFromObj = (obj: { [key: string]: unknown }, arr: unknown[]) =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => arr.includes(k)));

export const omitFromObj = (obj: { [key: string]: unknown }, arr: unknown[]) =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !arr.includes(k)));
