import styled from 'styled-components';
import Logo from '../../assets/img/branding/huler-logo-small.svg';

interface MaintenancePageProps {
  className?: string;
}

const MaintenancePage = ({ className }: MaintenancePageProps) => {
  return (
    <div className={className}>
      <div>
        <img alt='logo' src={Logo} />
        <h1>We'll be back.</h1>
        <br />
        <p>
          Don't worry, we're not The Terminator. <br /> We're just updating Huler for you.
        </p>
        <br />
        <p>Please check back soon.</p>
      </div>
    </div>
  );
};

const StyledMaintenancePage = styled(MaintenancePage)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--background);
  > div {
    display: flex;
    flex-direction: column;
  }
  img {
    height: 64px;
  }
  h1,
  p {
    text-align: center;
    color: var(--body-text-color);
  }
  h1 {
    font-size: 32px;
  }
  p {
    font-size: 22px;
  }
`;

export default StyledMaintenancePage;
