import * as React from 'react';
import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import jwtDecode from 'jwt-decode';
import { acceptOrganisationTerms } from 'api/wrappers/AdminUsersAPI';
import getUsersLocale from 'helpers/getUsersLocale';
import { useAsync } from 'hooks/useAsyncHook';
import Loader from 'components/UI/Loader/Loader';
import { getUser } from 'api/wrappers/AdminUsersAPI';
import { getOrganisationTermsAndConfig } from 'api/wrappers/OrganisationAPI';
import { fullName } from 'helpers/Utils';
import { removeSavedLoginDetails } from 'helpers/toDesktop';
import { platform } from '@todesktop/client-core';

const parseHulerUser = async (cognitoUser) => {
  const token = cognitoUser.signInUserSession.idToken.jwtToken;
  const decodedToken = jwtDecode(token);
  const organisationId = decodedToken['custom:organisation_id'] ? decodedToken['custom:organisation_id'] : 'MCG';
  const claims = await JSON.parse(decodedToken.encodedClaims);
  const userFromApi = await getUser({ organisationId, userId: claims.userUUID, detail: 'core,extended,private' });
  const hubAdminRoles = ['owner', 'hub-admin'];
  // TODO(sentry): Could not automatically migrate - see https://github.com/getsentry/sentry-javascript/blob/develop/MIGRATION.md#deprecate-hub
  const isHubAdmin = hubAdminRoles.some((role) => userFromApi?.privilegeLevel?.includes(role));
  const orgData = await getOrganisationTermsAndConfig();
  // If there are no terms in the org or policies are disabled, then mark as accepted
  const hasAcceptedTermsAndConditions =
    orgData.terms.itemCount === 0 || !orgData.config.arePoliciesEnabled || orgData.terms?.items[0]?.version === userFromApi?.organisationTermsVersion;

  // Set JWT cookie (required for SCORM content)
  document.cookie = `JWT-Token=${token}; path=/; domain=${window.location.hostname}`;

  Sentry.getCurrentScope().setUser({
    id: userFromApi.id,
  });

  const user = {
    organisationId,
    isHubAdmin,
    isOwner: userFromApi.privilegeLevel === 'owner',
    language: getUsersLocale() ?? 'en-GB',
    phoneNumber: userFromApi.phoneNumber,
    email: userFromApi.email,
    userId: userFromApi.id,
    hasAcceptedTermsAndConditions,
    privilegeLevel: userFromApi.privilegeLevel,
    name: fullName(userFromApi),
    emailFrequency: userFromApi.emailFrequency,
    notificationVolume: userFromApi.notificationVolume,
    userObj: userFromApi,
    attributes: {
      department: userFromApi.attributes.department,
      gender: userFromApi.attributes.gender,
      geo: userFromApi.attributes.geo,
      location: userFromApi.attributes.location,
      startedat: userFromApi.attributes.startedat,
      role: userFromApi.attributes.role,
      ismanager: userFromApi.attributes.ismanager,
      employeeid: userFromApi.attributes.employeeid,
    },
  };
  return user;
};

async function bootstrapAuthData() {
  let user = null;
  try {
    if (Auth.userPool) {
      user = await Auth.currentAuthenticatedUser();
      if (user) {
        const hulerUser = await parseHulerUser(user);
        return hulerUser;
      }
    }
    return user;
  } catch (error) {
    return user;
  }
}

const AuthContext = React.createContext();

function AuthProvider(props) {
  const { data: user, status, error, isLoading, isIdle, isError, isSuccess, run, setData } = useAsync();

  React.useEffect(() => {
    const authDataPromise = bootstrapAuthData();
    run(authDataPromise);
  }, [run]);

  const login = React.useCallback(
    async (authUser) => {
      const hulerUser = await parseHulerUser(authUser);
      // Set the org in local storage so we can prefill the org search input
      localStorage.setItem('orgId', hulerUser.organisationId);
      setData(hulerUser);
    },
    [setData],
  );
  const logout = React.useCallback(
    (organisationId) =>
      Auth.signOut().then(async () => {
        if (platform.todesktop.isDesktopApp()) {
          await removeSavedLoginDetails();
        }
        window.location = `/${organisationId}/login`;
      }),
    [],
  );
  const logoutAndReset = React.useCallback(
    (organisationId) =>
      Auth.signOut().then(async () => {
        if (platform.todesktop.isDesktopApp()) {
          await removeSavedLoginDetails();
        }
        setData(null);
        window.location = `/${organisationId}/passwordreset`;
      }),
    [setData],
  );
  const acceptTermsAndConditions = React.useCallback(
    async (version) => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const currentSession = await Auth.currentSession();

        await acceptOrganisationTerms(user.userId, version);

        return cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
          const { idToken } = session;
          setData({ ...user, JWT: idToken, hasAcceptedTermsAndConditions: true });
        });
      } catch (e) {
        return e;
      }
    },
    [setData, user],
  );

  const setTermsNotAccepted = React.useCallback(
    () =>
      Auth.signOut().then(() => {
        setData(null);
      }),
    [setData],
  );

  const setLanguage = React.useCallback((locale) => setData({ ...user, language: locale }), [setData, user]);

  const value = React.useMemo(
    () => ({ user, login, logout, logoutAndReset, acceptTermsAndConditions, setTermsNotAccepted, setLanguage }),
    [user, login, logout, logoutAndReset, acceptTermsAndConditions, setTermsNotAccepted, setLanguage],
  );

  if (isLoading || isIdle) {
    return <Loader fullscreen style={{ width: '80px', height: '80px' }} />;
  }

  if (isError) {
    return <div>Error Occurred {error.message} </div>;
  }

  if (isSuccess) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within an Auth Provider`);
  }

  return context;
}

export { AuthProvider, useAuth };
